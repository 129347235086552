import parse from "html-react-parser";
import React from "react";

import { iconsDefault, getIcon } from "./constIcons";

interface IconProps {
  name: keyof typeof iconsDefault;
  size: string;
  color: string;
}
const Icon = ({ name, size, color }: IconProps) => {
  return <>{parse(getIcon({ icon: name, size, color }))}</>;
};

export default Icon;

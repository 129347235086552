import {
  IntroSectionContent,
  Card,
  SafeguardYourInformationContent,
} from "@/components/site/pages/Security/types";
import { MainSection } from "@/components/site/parts/MainSection/index";
import { PageActive } from "@/types/entities/page";
import parse from "html-react-parser";
// import classNames from "classnames";
import { Trans } from "next-i18next";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

interface DynamicPageProps {
  pageData: PageActive;
}
const SecurityPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");
  const sections = [
    { key: "introSection", type: "" },
    { key: "supportSection", type: "" },
    { key: "safeguardYourInformation", type: "" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`Security.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const { introSection, supportSection, safeguardYourInformation } = sectionContents as {
    introSection: IntroSectionContent;
    supportSection: Card[];
    safeguardYourInformation: SafeguardYourInformationContent;
  };

  return (
    <>
      <MainSection
        imgUrl="/images/site/security/main-img.webp"
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classImage="max-h-[260px] xs:max-h-[500px] md:min-h-[609px] md:max-w-[609px] object-center">
        <h1 className="grid justify-items-start">
          <span className="relative inline text-site-text-28-33 font-medium text-site-text-blue-2 before:absolute before:bottom-[-15px] before:left-[0px] before:right-[0px] before:-z-[1] before:h-[24px] before:w-[108%] before:bg-[url('/images/site/security/vector-1.svg')] before:bg-contain before:bg-no-repeat sm:text-site-text-38-38 md3:text-site-text-56-67 md3:before:bottom-[-3px]">
            {t("Security.Your Security")}
          </span>
          <span className="relative inline text-site-text-28-33 font-medium text-site-text-blue-2 before:absolute before:bottom-[-22px] before:right-[-12px] before:h-[25px] before:w-[18px] before:bg-[url('/images/site/security/vector-2.svg')] before:bg-contain before:bg-no-repeat sm:text-site-text-38-38 md3:text-site-text-56-67 md3:before:bottom-[-8px] md3:before:right-[-25px] md3:before:h-[32px] md3:before:w-[28px]">
            {t("Security.Is Our Priority")}
          </span>
        </h1>
        <p className="mt-5 text-site-text-16-24 font-medium text-site-text-blue-2 sm:text-site-text-18-26 md3:text-site-text-28-40">
          {t("Security.Ensuring the confidentiality of your personal information is our top priority.")}
        </p>
      </MainSection>
      <div className="bg-site-background-blue">
        <div className="container py-8 sm:py-16">
          <h2 className="mb-4 text-site-text-28-33 font-light text-site-text-white sm:text-site-text-48-57">
            {introSection.title}
          </h2>
          <p className="mb-8 text-site-text-16-24 font-light text-site-text-white sm:mb-16 sm:text-site-text-18-27">
            {introSection.description}
          </p>
          <div className="flex flex-col gap-6 text-site-text-32-38 sm:flex-row sm:gap-16">
            {introSection.cards.map((card, index) => (
              <div key={index} className="basis-1/3 rounded-2xl bg-site-background-white p-6">
                <div className="mb-2 flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-site-background-gray3 p-3.5">
                  <Image
                    className="h-full w-auto"
                    src={`/images/site/security/${card.icon}`}
                    alt={card.title}
                    width="64"
                    height="64"
                  />
                </div>
                <h5 className="text-site-text-20-28 font-[500] sm:text-site-text-24-33">{card.title}</h5>
                <p className="text-site-text-16-24 font-light sm:text-site-text-14-21">{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container py-8 sm:py-32">
        <div className="flex flex-col-reverse items-center gap-[5.1%] pb-8 sm:flex-row sm:pb-32">
          <Image
            className="w-full basis-full rounded-[40px] sm:w-[52.8%] sm:basis-[52.8%]"
            src={`/images/site/security/${safeguardYourInformation.imageUrl}`}
            alt={safeguardYourInformation.title || "We Safeguard Your Information"}
            width="660"
            height="440"
          />
          <div className="mb-6 basis-[42.1%] sm:mb-0">
            <h2 className="mb-6 text-site-text-28-33 font-medium sm:mb-10 sm:text-site-text-48-57">
              {safeguardYourInformation.title}
            </h2>
            <p className="text-site-text-18-27 font-medium">{safeguardYourInformation.description}</p>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          {supportSection.map((card, index) => (
            <div key={index} className="relative flex flex-row items-start gap-4">
              <div className="hidden h-16 w-16 shrink-0 rounded-full bg-site-background-gray4 p-3.5 sm:flex">
                <Image
                  className="h-full w-auto"
                  src={`/images/site/security/${card.icon}`}
                  alt={card.title}
                  width="64"
                  height="64"
                />
              </div>
              <div className="flex flex-col">
                <div className="mb-4 flex flex-row items-center gap-4 sm:mb-0">
                  <div className="flex h-16 w-16 shrink-0 rounded-full bg-site-background-gray4 p-3.5 sm:hidden">
                    <Image
                      className="h-full w-auto"
                      src={`/images/site/security/${card.icon}`}
                      alt={card.title}
                      width="64"
                      height="64"
                    />
                  </div>
                  <h5 className="mb-2 py-2.5 text-site-text-20-28 font-medium sm:py-0 sm:text-site-text-24-33">
                    {card.title}
                  </h5>
                </div>

                <p className="text-site-text-16-24 font-extralight sm:text-site-text-18-27">
                  {parse(card.description)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-site-background-gray">
        <div className="container py-4 sm:py-16">
          <h2 className="mb-4 text-left text-site-text-28-33 font-medium sm:mb-5 sm:text-center sm:text-site-text-48-57">
            <Trans i18nKey="Security.Certified for Your Safety" defaults="" />
          </h2>
          <p className="mb-4 text-left text-site-text-16-23 font-extralight sm:mb-5 sm:text-center sm:text-site-text-18-27">
            <Trans i18nKey="Security.We’re a HIPAA covered entity in delivering..." defaults="" />
          </p>
          <p className="mb-4 text-left text-site-text-16-23 font-extralight sm:mb-5 sm:text-center sm:text-site-text-18-27">
            <Trans i18nKey="Security.SimpleTherapy, Inc. has implemented..." defaults="" />
          </p>
          <p className="mb-4 text-left text-site-text-16-23 font-extralight sm:mb-5 sm:text-center sm:text-site-text-18-27">
            <Trans i18nKey="Security.SimpleTherapy, Inc. conducts annual..." defaults="" />
          </p>
          <div className="flex justify-center gap-10">
            <Image
              className="max-h-[80px] w-auto object-cover"
              src="/images/site/security/HIPPA.png"
              alt="HIPPA"
              width="132"
              height="80"
            />
            <Image
              className="max-h-[80px] w-auto object-cover"
              src="/images/site/security/Prescient-SOC2-Type-2-Badge.png"
              alt="HIPPA"
              width="89"
              height="89"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityPageComponent;

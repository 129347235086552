import Icon from "@/components/Icon";
import Snowflakes from "@/components/site/parts/Snowflakes";
import classNames from "classnames";
import dynamic from "next/dynamic";
import React from "react";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const HolidayGreetingsPageComponent = () => {
  return (
    <>
      <Snowflakes />
      <div className={classNames("container relative pb-8 lg:pb-16")}>
        <div className={classNames("flex items-center justify-center")}>
          <div className={classNames("mb-2 flex items-center")}>
            <Icon name="volumeLeft" size="20" color="white"></Icon>
            <span className={classNames("animate-scale")}>
              <Icon name="volumeRight" size="20" color="white"></Icon>
            </span>
          </div>
          <p
            className={classNames(
              "mb-2 ml-2 text-center text-site-text-16-23 text-white md:text-site-text-24-33"
            )}>
            Be sure to unmute the video for the full experience!
          </p>
        </div>
        <ReactPlayer
          className="react-player-holiday flex !w-full items-center justify-center lg:!h-[710px]"
          url="https://vimeo.com/1039654988"
          background="transparent"
          controls
          loop
          muted={true}
          playing={true}
          volume={0.3}
          width="100%"
          preload="auto"
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                modestbranding: 1,
                rel: 0,
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default HolidayGreetingsPageComponent;
